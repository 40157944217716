<template>
	<div class="main-wrap">
		<div class="flex w100">
			<div><MainMenu :list="menus"/></div>
	
			<div id="Page-APD-Content" class="w850">
				<div class="title-h2 live">Asia-Pacific Design {{id}}</div>
				<div class="title-h1" v-if="$root.lang =='CN'">{{L("第")}}{{$root.n2c(id)}}{{L("届亚太设计年鉴")}}</div>
				<!-- <div class="time">{{$root.format('yyyy-MM-dd HH:mm:ss',new Date(item.createAt))}}</div> -->
				
				<!-- <div class="mt-20"></div>
				<div class="mt-20"></div> -->
				<div class="content" v-if="item.id && $root.lang =='CN'" v-html="L(item.content)"></div>
				<template v-if="item.id && hasSilder && item.content1 && $root.lang =='CN'">
					<div class="mt-20"></div>
					<div :style="isHalf?'width:50%;margin:0 auto;':''">
						<div ref="sliderBox">
							<slider animation="normal"  :height="height2" :control-btn="false" :autoplay="true">
								<slider-item v-for="(item,index) in item.images" :key="item.fid" @click="showViewer(index)">
									<CoverWrap unanimation :src="item.path+'?x-oss-process=image/resize,w_1440'" :width="width" :height="height1"/>
								</slider-item>
								<div class="swiper-pagination" slot="pagination"></div>
							</slider>
						</div>
					</div>
					<div class="mt-20"></div>
					<div class="content" v-html="L(item.content1)"></div>
				</template>
				
				<div class="content" v-if="item.id && $root.lang =='EN'" v-html="item.econtent"></div>
				<template v-if="item.id && hasSilder && item.econtent1 && $root.lang =='EN'">
					<div class="mt-20"></div>
					<div :style="isHalf?'width:50%;margin:0 auto;':''">
						<div ref="sliderBox">
							<slider animation="normal" :height="height2" :control-btn="false" :autoplay="true">
								<slider-item v-for="(item,index) in item.images" :key="item.fid" @click="showViewer(index)">
									<CoverWrap unanimation :src="item.path+'?x-oss-process=image/resize,w_1440'" :width="width" :height="height1"/>
								</slider-item>
								<div class="swiper-pagination" slot="pagination"></div>
							</slider>
						</div>
					</div>
					<div class="mt-20"></div>
					<div class="content" v-html="item.econtent1"></div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import MainMenu from '/src/views/MainMenu';
import { Slider, SliderItem } from 'vue-easy-slider'
import CoverWrap from '/src/components/CoverWrap.vue';
export default {
  name: 'Page-APD-Content',
  components: {
    MainMenu,
		Slider, SliderItem,
		CoverWrap
  },
  data(){
    return {
			id: this.$route.params.id,
			item:{},
			hasSilder:false,
			menus:[],
			width:'100px',
			height1:'100px',
			height2:'150px',
			isHalf:false
    };
  },
	watch:{
		$route(){
			this.initAPD()
		}
	},
  async mounted () {
		await this.initAPD();
		
		var list = this.$root.awardStageList || [];
		if(list){
			var menus = []
			list.forEach(item=>{
				if(item.status_apd == 1 ){
					menus.push ({
						title:item.name,
						path: item.status_apd == 1 ? "/APD/Content/"+item.id : "",
						id:item.id
					})
				}
			})
			this.menus = menus;
			
		}
  },
  methods: {
		async initAPD(){
			this.id = this.$route.params.id
			var result = await this.request.zd.get("APD_"+this.$route.params.id);
			
			if(result && result.id){
				let imagesHTML = '';
				if(result.images && result.images.length){
					this.hasSilder = true;
					
					if(result.content && this.$root.lang =='CN'){
						let contents = result.content.split("[IMAGES]");
						result.content = contents.shift();
						result.content1 = contents.join("");
						if(!result.content1){
							contents = result.content.split("[IMAGES/50%]");
							if(contents.length>1){
								this.isHalf = true;
							}
							result.content = contents.shift();
							result.content1 = contents.join("");
						}
						
					}
					if(result.econtent && this.$root.lang =='EN'){
						let econtents = result.econtent.split("[IMAGES]");
						result.econtent = econtents.shift();
						result.econtent1 = econtents.join("");
						
						if(!result.econtent1){
							econtents = result.econtent.split("[IMAGES/50%]");
							if(econtents.length>1){
								this.isHalf = true;
							}
							result.econtent = econtents.shift();
							result.econtent1 = econtents.join("");
						}
					}
				} else {
					if(result.content){
						result.content = result.content.replace("[IMAGES]","");
						result.content = result.content.replace("[IMAGES/50%]","");
					}
					if(result.econtent){
						result.econtent = result.econtent.replace("[IMAGES]","");
						result.econtent = result.econtent.replace("[IMAGES/50%]","");
					}
				}
				
				this.$set(this,'item',result);
			} else {
				this.$set(this,'item',{});
			}
			
			this.$nextTick(()=>{
				if(this.$refs.sliderBox){
					console.log(window.document.getElementById("Page-APD-Content").clientWidth,this.isHalf)
					var width = parseInt(window.document.getElementById("Page-APD-Content").clientWidth) * (this.isHalf?0.5:1);
					this.height1 = parseInt(726 / 1091 * width) + "px" ;
					this.height2 = parseInt(726 / 1091 * width) + 60 + "px" ;
					this.width = width + "px";
				}
				
			})
		},
		showViewer(index){
			// this.$root.showViewer(this.item.images,index);
		},
  }
}
</script>

<style scoped lang="less">
#Page-APD-Content{
	font-size:16px;
	line-height: 1.5;
	padding-bottom:120px;
	.content,/deep/.content *{
		font-family: 'Roboto';
	}
	/deep/p{
		line-height: 1.8;
	}
	/deep/img{
		max-width: 100%;
	}
	.title{
		font-size:30px;
		line-height: 1.5;
	}
	.etitle{
		font-size:24px;
		line-height: 1.2;
	}
}
</style>